<template>
  <div>
    <WrapperSubHeader>
      <span
        >{{ $t("adminProjects.projects") }} <span class="font-normal text-base" v-if="!loading">/ (#{{ project.id }}) {{ project.name }} ({{ project.company }})</span></span
      >
    </WrapperSubHeader>
    <div class="w-full mt-5 mb-20">
      <div class="container-xl py-2 mx-auto w-full my-4 flex flex-col">
        <router-link :to="{ name: 'Admin Projects' }" class="text-left bg-gray-200 btn btn-ghost shadow-none hover:bg-gray-300">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="w-5 h-5 mx-2 stroke-current">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
          </svg>
          {{ $t("adminProjects.back") }}
        </router-link>
        <div v-if="loading" class="mt-12 w-full flex justify-center items-center">
          <Loader :onlyLoader="true" />
        </div>
        <TransitionFade>
          <div v-if="!loading">
            <div class="grid grid-cols-1 gap-4">
              <div class="bg-white shadow overflow-hidden mt-5 sm:rounded-lg">
                <div class="px-4 h-16 flex items-center">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    {{ $t("adminProjects.projectInfo") }}
                  </h3>
                </div>
                <div class="border-t border-gray-200">
                  <div class="grid grid-cols-2 md:grid-cols-4 gap-4 py-3">
                    <div class="px-4 py-3">
                      <dt class="text-sm font-medium text-gray-500">{{ $t("adminProjects.company") }}</dt>
                      <dd class="pt-2.5 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {{ project.company }}
                      </dd>
                    </div>
                    <div class="px-4 py-3">
                      <dt class="text-sm font-medium text-gray-500">{{ $t("adminProjects.created") }}</dt>
                      <dd class="pt-2.5 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <small class="text-xs font-semibold text-gray-600"
                          >{{ $moment(project.created_at).format("YYYY-MM-DD HH:mm") }} <i>({{ $moment(project.created_at).fromNow(true) }})</i></small
                        >
                      </dd>
                    </div>
                    <div class="px-4 py-3">
                      <dt class="text-sm font-medium text-gray-500">
                        {{ $t("adminProjects.updated") }}
                      </dt>
                      <dd class="pt-2.5 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <small class="text-xs font-semibold text-gray-600"
                          >{{ $moment(project.updated_at).format("YYYY-MM-DD HH:mm") }} <i>({{ $moment(project.updated_at).fromNow(true) }})</i></small
                        >
                      </dd>
                    </div>
                    <div class="px-4 py-3">
                      <dt class="text-sm font-medium text-gray-500">
                        {{ $t("adminProjects.syncDateProducts") }}
                      </dt>
                      <dd class="pt-2.5 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <small class="text-xs font-semibold text-gray-600" v-if="project.cache && project.cache.last_sync_top_products">
                          {{ $moment(project.cache.last_sync_top_products).format("YYYY-MM-DD HH:mm") }}
                          <i>({{ $moment(project.cache.last_sync_top_products).fromNow(true) }})</i>
                        </small>
                        <small class="text-xs font-semibold text-gray-600" v-else>
                          {{ $t("adminProjects.noRegister") }}
                        </small>
                      </dd>
                    </div>
                    <div class="px-4 py-3">
                      <dt class="text-sm font-medium text-gray-500">
                        {{ $t("adminProjects.syncDateCategory") }}
                      </dt>
                      <dd class="pt-2.5 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <small class="text-xs font-semibold text-gray-600" v-if="project.cache && project.cache.last_sync_top_categories">
                          {{ $moment(project.cache.last_sync_top_categories).format("YYYY-MM-DD HH:mm") }}
                          <i>({{ $moment(project.cache.last_sync_top_categories).fromNow(true) }})</i>
                        </small>
                        <small class="text-xs font-semibold text-gray-600" v-else>
                          {{ $t("adminProjects.noRegister") }}
                        </small>
                      </dd>
                    </div>
                    <div class="px-4 py-3">
                      <dt class="text-sm font-medium text-gray-500">
                        {{ $t("adminProjects.timezone") }}
                      </dt>
                      <dd class="pt-2.5 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span v-if="project.timezone">{{ project.timezone }}</span>
                      </dd>
                    </div>
                    <div class="px-4 py-3">
                      <dt class="text-sm font-medium text-gray-500">{{ $t("adminProjects.state") }}</dt>
                      <dd class="pt-2.5 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 uppercase" v-if="project.is_active">{{ $t("adminProjects.active") }}</span>
                        <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800 uppercase" v-if="!project.is_active">{{ $t("adminProjects.inactive") }}</span>
                      </dd>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="grid md:grid-rows-1 md:grid-cols-12 gap-4">
              <div class="row-span-1 row-end-1 col-span-12 md:col-span-4 bg-white shadow mt-5 sm:rounded-lg">
                <div class="px-4 h-16 flex items-center">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    {{ $t("adminProjects.metricas") }}
                  </h3>
                </div>
                <div class="border-t border-gray-200">
                  <div class="flex w-full">
                    <div class="w-1/4 py-4 text-center border-r border-b border-gray-200">
                      <dt class="text-sm font-medium text-gray-500">
                        {{ $t("adminProjects.currency") || "" }}
                      </dt>
                      <dd class="pt-2.5 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800" v-tooltip.top="project?.currency ? project?.currency?.name : $t('audience.notData')">{{ project?.currency?.symbol || "-"}}</span>
                      </dd>
                    </div>
                    <div class="w-1/4 py-4 text-center border-r border-b border-gray-200">
                      <dt class="text-sm font-medium text-gray-500">Users</dt>
                      <dd class="pt-2.5 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {{ $filters.number(project.users.length ? project.users.length : 0) }}
                      </dd>
                    </div>
                    <div class="w-1/4 py-4 text-center border-r border-b border-gray-200">
                      <dt class="text-sm font-medium text-gray-500">
                        {{ $t("adminProjects.profiles") }}
                      </dt>
                      <dd class="pt-2.5 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {{ $filters.number(project.count_profiles) }}
                      </dd>
                    </div>
                    <div class="w-1/4 py-4 text-center border-b border-gray-200">
                      <dt class="text-sm font-medium text-gray-500">{{ $t("adminProjects.content") }}</dt>
                      <dd class="pt-2.5 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {{ $filters.number(project.count_content) }}
                      </dd>
                    </div>
                  </div>
                </div>
                <div class="border-b border-gray-200">
                  <div class="flex w-full">
                    <div class="w-1/4 py-4 text-center border-r border-gray-200">
                      <dt class="text-sm font-medium text-gray-500">
                        {{ $t("adminProjects.integrations") }}
                      </dt>
                      <dd class="pt-2.5 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {{ $filters.number(project.count_integrations) }}
                      </dd>
                    </div>
                    <div class="w-1/4 py-4 text-center border-r border-gray-200">
                      <dt class="text-sm font-medium text-gray-500">
                        {{ $t("adminProjects.segments") }}
                      </dt>
                      <dd class="pt-2.5 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {{ $filters.number(project.count_segments) }}
                      </dd>
                    </div>
                    <div class="w-1/4 py-4 text-center border-r border-gray-200">
                      <dt class="text-sm font-medium text-gray-500">
                        {{ $t("adminProjects.campaigns") }}
                      </dt>
                      <dd class="pt-2.5 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800" v-tooltip.top="$t('audience.notData')">{{"-"}}</span>
                      </dd>
                    </div>
                  </div>
                </div>

                <div class="px-4 h-16 flex items-center">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    {{ $t("adminProjects.integrations") }}
                  </h3>
                </div>
                <div class="border-t border-gray-200">
                  <div class="flex w-full flex-wrap">
                    <div class="w-1/4 py-4 text-center" v-for="channel in project.channels" :key="channel.id">
                      <dt class="text-sm font-medium text-gray-500 flex flex-col">
                        <img :src="channel.avatar_short" :alt="`${channel.name} logo`" class="w-10 h-10 object-contain my-2 mx-auto" />
                        {{ channel.name }}
                        <span class="my-2 px-2 w-2/3 block mx-auto text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 uppercase" v-if="channel.is_active">{{ $t("adminProjects.active") }}</span>
                        <span class="my-2 px-2 w-2/3 block mx-auto text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800 uppercase" v-if="!channel.is_active">{{ $t("adminProjects.inactive") }}</span>
                      </dt>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row-span-2 col-span-12 md:col-span-8 bg-white shadow mt-5 sm:rounded-lg">
                <div class="px-4 w-full h-16 sm:px-6 inline-flex items-center justify-between">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    {{ $t("adminProjects.addUsers") }}
                  </h3>
                  <div class="inline-flex gap-2">
                    <div class="relative">
                      <div class="input input-bordered inline-flex items-center justify-between w-72 px-3 focus:outline" @click="isAutocompleteOpen = !isAutocompleteOpen" v-click-away="closeAutocomplete" @keydown.esc="closeAutocomplete">
                        <input v-model="projectAttachUser.selected.name" type="text" class="rounded-md text-gray-900 w-full py-2 h-auto focus:outline-none" v-debounce:700ms="showAutocomplete" placeholder="Search User" />
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                        </svg>
                      </div>
                      <TransitionFade>
                        <ul class="absolute overflow-y-auto left-0 mt-2 bg-white dark:bg-gray-800 rounded-lg shadow-xl z-20 border divide-y divide-gray-100" v-if="isAutocompleteOpen" :class="[filteredResults.length > 7 ? 'h-72' : 'h-auto']">
                          <li class="h-14 w-72 text-sm flex flex-col items-start justify-center cursor-pointer hover:bg-gray-50 transition ease-out duration-300" v-for="user in filteredResults" :key="user.id" @click="selectUser(user)">
                            <span class="capitalize font-semibold text-gray-500 inline-block px-2">{{ user.name }}</span>
                            <span class="lowercase font-normal text-gray-400 inline-block px-2">{{ user.email }}</span>
                          </li>
                        </ul>
                      </TransitionFade>
                    </div>
                    <div class="mt-3 mx-2">
                      <checkbox v-model="sendEmail" :binary="true" v-tooltip.top="'Enviar email'" />
                    </div>
                    <button class="btn btn-primary btn-xs ml-auto" @click="attachUserProject()">
                      <svg v-if="!showSpinner" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="inline-block w-5 h-5 mr-2 stroke-current">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                      </svg>
                      <svg v-if="showSpinner" class="w-4 h-4 text-white animate-spin mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      {{ $t("adminProjects.addUser") }}
                    </button>
                  </div>
                </div>
                <div class="border-t border-gray-200">
                  <div v-if="projectDetachUser.updating">
                    <Loader :onlyLoader="true" v-if="loading" />
                  </div>
                  <div v-else>
                    <div class="align-middle inline-block min-w-full" v-if="project.users.length">
                      <table class="min-w-full divide-y divide-gray-200">
                        <tbody class="bg-white divide-y divide-gray-200">
                          <tr v-for="(user, userIndex) in project.users" :key="userIndex" class="hover:bg-gray-100 transition">
                            <td class="px-6 py-3 whitespace-nowrap flex flex-row gap-4 items-center">
                              <img :src="user.avatar" class="rounded-full w-10" />
                              <div class="text-sm text-gray-700 font-semibold">
                                {{ user.email }}
                              </div>
                            </td>
                            <td class="px-6 py-3 whitespace-nowrap">
                              <div class="text-sm text-gray-700 font-semibold">
                                {{ user.name }}
                              </div>
                            </td>
                            <td class="px-6 py-3 whitespace-nowrap">
                              <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 uppercase" v-if="user.is_active">{{ $t("adminProjects.active") }}</span>
                              <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800 uppercase" v-if="!user.is_active">{{ $t("adminProjects.inactive") }}</span>
                            </td>
                            <td class="py-3 whitespace-nowrap">
                              <a :href="`${env.VUE_APP_URL_API}/api/2.0/auth/login/user/${user.id}?redirect=${env.VUE_APP_URL}/login`" v-if="user.can_be_personified" class="btn btn-info btn-xs px-2.5" v-tooltip.top="$t('users.loginWithUser')">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="inline-block w-5 h-5 mx-auto stroke-current">
                                  <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
                                </svg>
                              </a>
                            </td>
                            <td class="py-3 whitespace-nowrap">
                              <button class="btn btn-error btn-xs px-2.5" @click="detachUserProject(user.id)">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="inline-block w-5 h-5 mx-auto stroke-current">
                                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                </svg>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div v-else>
                      <div class="flex flex-col items-center my-10">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-14 text-gray-300">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                        </svg>
                        <span class="px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">{{ $t("adminProjects.notfound") }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TransitionFade>
      </div>
    </div>
  </div>
</template>

<script>
import WrapperSubHeader from "@/components/WrapperSubHeader"
import Loader from "@/components/UI/Loader"
import TransitionFade from "@/components/TransitionFade"
import { useI18n } from "vue-i18n"
import Checkbox from "primevue/checkbox"
// import AutoComplete from 'primevue/autocomplete';

export default {
  name: "ProjectShow",
  props: ["id"],
  components: {
    // AutoComplete,
    WrapperSubHeader,
    TransitionFade,
    Loader,
    Checkbox
  },

  data() {
    const { t, locale } = useI18n()
    return {
      env: process.env,
      t,
      locale,
      loading: false,
      project: {
        users: [],
        segments: [],
        channels: []
      },
      projectAttachUser: {
        updating: false,
        modal: false,
        users: [],
        selected: {
          name: "",
          id: null,
          email: ""
        },
        filtered: []
      },
      projectDetachUser: {
        updating: false
      },
      showModal: false,
      showSpinner: false,
      isAutocompleteOpen: false,
      sendEmail: false
    }
  },
  mounted() {
    this.loadProfile()
  },
  computed: {
    filteredResults() {
      return this.$store.state.AdminUsers.data.filter((user) => {
        return user.name.toLocaleLowerCase().includes(this.projectAttachUser.selected.name.toLocaleLowerCase())
      })
    }
  },
  watch: {
    "projectAttachUser.selected.name": {
      handler(newVal) {
        if (newVal === "") {
          this.closeAutocomplete()
          this.projectAttachUser.selected = { name: "", id: null, email: "" }
        }
      }
    }
  },
  methods: {
    showAutocomplete: function () {
      this.isAutocompleteOpen = true
    },
    closeAutocomplete: function () {
      this.isAutocompleteOpen = false
    },
    selectUser: function (user) {
      this.projectAttachUser.selected = user
    },
    loadProfile: async function () {
      this.loading = true
      this.$store.dispatch("AdminUsers/list", {})
      this.$store.dispatch("AdminProjects/show", this.id).then((response) => {
        if (response.status == 500) {
          this.$toast.error("Ocurrió un error, por favor reintente más tarde")
        }
        if (response.status == 200) {
          this.project = response.data.data
        }
        this.loading = false
      })
    },
    showModalAddUser: function () {
      this.projectAttachUser.modal = true
    },
    closeModalAddUser: function () {
      this.projectAttachUser.modal = false
      this.projectAttachUser.selected = ""
    },
    attachUserProject: async function () {
      this.showSpinner = true
      let id = this.projectAttachUser.selected.id
      this.projectAttachUser.updating = true
      let response = await this.$store.dispatch("AdminProjects/UserAttach", {
        id: this.id,
        userId: id,
        sendEmail: this.sendEmail
      })
      if (response.status == 500) {
        this.loading = false
        this.$toast.error("Ocurrió un error, por favor reintente más tarde")
        this.projectAttachUser.updating = false
      }
      if (response.status == 204) {
        this.$store.dispatch("AdminProjects/show", this.id).then((response) => {
          if (response.status == 500) {
            this.$toast.error("Ocurrió un error, por favor reintente más tarde")
          }
          if (response.status == 200) {
            this.project.users = response.data.data.users
          }
          this.$toast.success("Se agrego con éxito")
          this.projectAttachUser.updating = false
          this.closeModalAddUser()
        })
      }
      setTimeout(() => {
        this.showSpinner = false
      }, 2000)
    },
    detachUserProject: async function (id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!"
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.projectDetachUser.updating = true
          let response = await this.$store.dispatch("AdminProjects/UserDetach", {
            id: this.id,
            userId: id
          })
          if (response.status == 500) {
            this.$toast.error("Ocurrió un error, por favor reintente más tarde")
            this.projectDetachUser.updating = false
          }

          if (response.status == 204) {
            this.$store.dispatch("AdminProjects/show", this.id).then((response) => {
              if (response.status == 500) {
                this.$toast.error("Ocurrió un error, por favor reintente más tarde")
              }
              if (response.status == 200) {
                this.project.users = response.data.data.users
              }
              this.$toast.success("Se elimino con éxito")
              this.projectDetachUser.updating = false
            })
          }
        }
      })
    }
  }
}
</script>
